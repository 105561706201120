import Related from '../../../../models/related/Related';
import { RelatedContentModel } from '../../../Partials/Sidebar/related-content/models/related-content.model';
import { GeneralContentModel } from '../../../Partials/Sidebar/GeneralContentAttributes/models/GeneralContentModel';
import Article from '../../../../models/article/Article';
import { UrlsComponentModel } from '../../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import Urls from '../../../../models/urls/Urls';
import Seo from '../../../../models/seo/Seo';
import { SeoComponentModel } from '../../../Partials/Sidebar/Seo/models/SeoComponentModel';
import { TagsModel } from '../../../Partials/Sidebar/tags/models/tags.model';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';
import Global from '../../../Partials/Blocky/helpers/global.helpers';
import { ArticleAutoTaggingScope, CompetitionIds } from '../../../../models/article/ArticleJson';
import AdvancedFilterModel from '../../../Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { store } from '../../../../store/store';
import { getCurrentUserUrl } from '../../../../global-helpers/global.helpers';
import { ContentTypes } from '../../../../constants/content-types';
import { updateAiArticlesAdvancedSearchFilters } from '../../../../store/action-creators/ai-articles-action-creator';
import {
	returnObjectForArticleFiltersUpdate,
	returnObjectForExternalArticlesFiltersUpdate,
} from '../../../../store/action-creators/ArticleActionCreator';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { featuresService } from '../../../../App';

// Related content
export function relatedContentsToRelatedContentModel(
	relatedSports: Related[],
	relatedContent: Related[],
	relatedMatches: Related[],
): RelatedContentModel {
	return {
		relatedSports,
		relatedContent,
		relatedMatches,
	};
}

export function relatedContentsToTagsModel(
	relatedSports: Related[],
	relatedTags: Related[],
	relatedMatches: Related[],
	tags: any[],
): TagsModel {
	return {
		relatedSports,
		relatedTags,
		relatedMatches,
		linkedTags: tags,
	};
}

// URLs related

export function urlsComponentModelToArticle(urlsModel: UrlsComponentModel, article: Article): Article {
	const urls: Urls = Urls.builder(article.urls)
		.withCanonicalUrl(urlsModel.canonicalUrl)
		.withExternalUrl(urlsModel.externalUrl)
		.withPublicUrlDesktop(urlsModel.publicUrlDesktop ? urlsModel.publicUrlDesktop : '')
		.withPublicUrlMobile(urlsModel.publicUrlMobile ? urlsModel.publicUrlMobile : '')
		.withPublicUrlAmp(urlsModel.publicUrlAmp ? urlsModel.publicUrlAmp : '')
		.build();
	const seo: Seo = Seo.builder(article.seo).withRedirectType(urlsModel.redirectType).build();

	return Article.builder(article).withSeo(seo).withUrls(urls).build();
}

export function articleToUrlsComponentModelForCreate(article: Article): UrlsComponentModel {
	return {
		externalUrl: article.urls && article.urls.externalUrl ? article.urls.externalUrl : '',
		canonicalUrl: article.urls && article.urls.canonicalUrl ? article.urls.canonicalUrl : '',
		redirectType: article.seo && article.seo.redirectType ? article.seo.redirectType : '',
		publicUrlDesktop: article.urls && article.urls.publicUrlDesktop ? article.urls.publicUrlDesktop : '',
		publicUrlMobile: article.urls && article.urls.publicUrlMobile ? article.urls.publicUrlMobile : '',
		publicUrlAmp: article.urls && article.urls.publicUrlAmp ? article.urls.publicUrlAmp : '',
	};
}

export function articleToUrlsComponentModelForEdit(article: Article): UrlsComponentModel {
	if (article) {
		return {
			externalUrl: article.urls && article.urls.externalUrl ? article.urls.externalUrl : '',
			canonicalUrl: article.urls && article.urls.canonicalUrl ? article.urls.canonicalUrl : '',
			redirectType: article.seo && article.seo.redirectType ? article.seo.redirectType : '',
			publicUrlDesktop: article.urls && article.urls.publicUrlDesktop ? article.urls.publicUrlDesktop : '',
			publicUrlMobile: article.urls && article.urls.publicUrlMobile ? article.urls.publicUrlMobile : '',
			publicUrlAmp: article.urls && article.urls.publicUrlAmp ? article.urls.publicUrlAmp : '',
		};
	}

	return {} as UrlsComponentModel;
}

// General content related

export function generalContentModelToArticle(genContent: GeneralContentModel, article: Article) {
	return Article.builder(article)
		.withPublishedUntil(genContent.publishedUntil)
		.withPublishedAt(genContent.publishedAt)
		.withCategory(genContent.category)
		.withAdditionalCategories(genContent.categories)
		.withAuthors(genContent.authors)
		.withCustomAuthor(genContent.customAuthor)
		.withStatus(genContent.status)
		.withLanguage(genContent.language)
		.withType(genContent.type)
		.withDistributionRegions(genContent.distributionRegions)
		.withDistributionChannels(genContent.distributionChannels)
		.withOrigin(genContent.origin)
		.withComments({ policy: genContent.commentsPolicies })
		.build();
}

export function articleToGeneralContentModelForCreate(article: Article, type: ContentAttributes, status: ContentAttributes) {
	let genContentType: string = (type && type.slug) || '';
	let genContentStatus: string = (status && status.slug) || '';
	if (article && typeof article.type == 'string') genContentType = article.type;
	if (article && typeof article.status == 'string') genContentStatus = article.status;

	let genContent: GeneralContentModel = {
		type: genContentType || '',
		status: genContentStatus || '',
		customAuthor: article.customAuthor,
		authors: article.authors,
		categories: article.additionalCategories,
		category: article.category,
		publishedAt: article.publishedAt,
		publishedUntil: article.publishedUntil,
		language: article.language,
		allowComments: false,
		distributionRegions: article.distributionRegions,
		distributionChannels: article.distributionChannels,
		origin: article.origin,
		commentsPolicies: article.comments && article.comments.policy,
		properties: article.properties,
	};

	return genContent;
}

export function articleToGeneralContentModelForEdit(article: Article) {
	let genContent = {} as GeneralContentModel;

	if (article) {
		genContent = {
			type: article.type,
			status: article.status,
			customAuthor: article.customAuthor,
			authors: article.authors,
			categories: article.additionalCategories,
			category: article.category,
			publishedAt: article.publishedAt,
			publishedUntil: article.publishedUntil,
			language: article.language,
			allowComments: false,
			distributionRegions: article.distributionRegions,
			distributionChannels: article.distributionChannels,
			origin: article.origin,
			commentsPolicies: article.comments && article.comments.policy,
			properties: article.properties,
		};
	}

	return genContent;
}

// SEO related

export function seoComponentModelToArticle(seoModel: SeoComponentModel, article: Article): Article {
	const seo: Seo = Seo.builder(article.seo)
		.withKeywords(seoModel.keywords)
		.withFollow(seoModel.following)
		.withIndex(seoModel.indexing)
		.withTitle(Global.stripEmoji(seoModel.title))
		.withDescription(seoModel.description)
		.withSlug(Global.stripEmoji(seoModel.slug))
		.withAutoSlug(seoModel.autoSlug)
		.withAutoTitle(seoModel.autoTitle)
		.withJsonLD(seoModel.jsonld)
		.build();

	return Article.builder(article).withSeo(seo).build();
}

export function articleToSeoComponentModelForCreate(article: Article): SeoComponentModel {
	return {
		keywords: article.seo && article.seo.keywords ? article.seo.keywords : [],
		following: article.seo ? article.seo.follow : true,
		indexing: article.seo ? article.seo.index : true,
		title: article.seo && article.seo.title ? Global.stripEmoji(article.seo.title) : '',
		description: article.seo && article.seo.description ? article.seo.description : '',
		slug: article.seo && article.seo.slug ? Global.stripEmoji(article.seo.slug) : '',
		contentTitle: article && article.title ? Global.stripEmoji(article.title) : '',
		autoSlug: article && article.seo ? article.seo.autoSlug : false,
		autoTitle: article && article.seo ? article.seo.autoTitle : false,
		jsonld: article && article.seo && article.seo.jsonld ? article.seo.jsonld : '',
	};
}

export function articleToSeoComponentModelForEdit(article: Article): SeoComponentModel {
	if (article) {
		return {
			keywords: article.seo && article.seo.keywords ? article.seo.keywords : [],
			following: article.seo ? article.seo.follow : true,
			indexing: article.seo ? article.seo.index : true,
			title: article.seo && article.seo.title ? Global.stripEmoji(article.seo.title) : '',
			description: article.seo && article.seo.description ? article.seo.description : '',
			slug: article.seo && article.seo.slug ? Global.stripEmoji(article.seo.slug) : '',
			contentTitle: article.title ? Global.stripEmoji(article.title) : '',
			autoSlug: article.seo ? article.seo.autoSlug : false,
			autoTitle: article.seo ? article.seo.autoTitle : false,
			jsonld: article.seo && article.seo.jsonld ? article.seo.jsonld : '',
		};
	}

	return {} as SeoComponentModel;
}

export const extractIds = (content: any) => {
	if (content && content.length > 0) {
		return content.map((item: any) => item.id).join();
	}

	return [];
};

export const remmapContentFilters = (
	article: any,
	video: any,
	gallery: any,
	image: any,
	playingSurface?: any,
	imago?: any,
	wikiPages?: any,
	liveBlogs?: any,
	jwVideoFilters?: any,
	getty?: any,
	externalArticlesFilters?: any,
) => {
	return {
		articleFilters: article,
		videoFilters: video,
		galleryFilters: gallery,
		imageFilters: image,
		playingSurfaceFilters: playingSurface,
		imagoFilters: imago,
		wikiPagesFilters: wikiPages,
		liveBlogsFilters: liveBlogs,
		jwFilters: jwVideoFilters,
		gettyFilters: getty,
		externalArticlesFilters: externalArticlesFilters,
	};
};

export const updateAutoTaggingScope = (
	newTags: { sport: string; competition_ids: CompetitionIds }[],
	setAutoTaggingScope: (scope: ArticleAutoTaggingScope) => any,
	autoTaggingScope: ArticleAutoTaggingScope | null | undefined,
) => {
	const getUniqueCompetitionsIds = (previousCompetitionIds: CompetitionIds = []): ArticleAutoTaggingScope['competition_ids'] => {
		const competitionIdsFromNewTags = newTags.reduce<CompetitionIds>(
			(mergedArray, tag) => [...mergedArray, ...(tag.competition_ids || [])],
			[],
		);
		const competitionIds = [...previousCompetitionIds, ...competitionIdsFromNewTags];
		const uniqueCompetitionIds = competitionIds.reduce<CompetitionIds>((mergedArray, competitionId) => {
			if (!mergedArray.includes(competitionId)) {
				return [...mergedArray, competitionId];
			}
			return mergedArray;
		}, []);

		return uniqueCompetitionIds;
	};

	let newAutoTaggingScope: ArticleAutoTaggingScope;

	if (autoTaggingScope && !!autoTaggingScope.sport && !!autoTaggingScope.competition_ids) {
		newAutoTaggingScope = {
			sport: autoTaggingScope.sport, // Sport is determined by the first tag and does not change
			competition_ids: getUniqueCompetitionsIds(autoTaggingScope.competition_ids),
		};
	} else {
		newAutoTaggingScope = {
			sport: newTags[0].sport,
			competition_ids: getUniqueCompetitionsIds(),
		};
	}

	setAutoTaggingScope(newAutoTaggingScope);
};

export enum articleOrigins {
	EXTERNAL = 'external',
	INTERNAL = 'internal',
	AI_ARTICLE = 'ai',
}

export enum articleFilters {
	EXTERNAL_FILTERS = 'externalArticlesFilters',
	AI_ARTICLES_FILTERS = 'aiArticlesListingFilters',
}

// Ai Articles
export const isOriginAvailable = (origin: articleOrigins): boolean => {
	try {
		return store.getState().origins.articleOrigins.findIndex((articleOrigin: ContentAttributes) => articleOrigin.slug === origin) >= 0;
	} catch (error) {
		console.error(error);
	}
	return false;
};

export const appendCorrectArticleFilter = (filters: any, originType: articleOrigins) => {
	if (filters) {
		filters.searchText = filters.searchText ? filters.searchText : '';
		if (isOriginAvailable(originType)) {
			filters.articleOrigin = store
				.getState()
				.origins.articleOrigins.find((articleOrigin: ContentAttributes) => articleOrigin.slug === originType);
		}
	}

	return filters;
};

export const isAiArticleSection = (currentUrl: string = getCurrentUserUrl()): boolean => {
	return currentUrl.includes('/smp/ai-articles');
};

export const isExternalArticleSection = (currentUrl: string = getCurrentUserUrl()): boolean => {
	return currentUrl.includes('/smp/rsm-articles');
};

// if there are no origin available -> do not render any data
export const shouldRenderArticlesRows = (contentType: ContentTypes, origin: articleOrigins, isSectionFn: () => boolean) => {
	return isSectionFn() && isOriginAvailable(origin) && contentType === ContentTypes.ARTICLE;
};

// this is because we are applying by default the article origin filter
export const hideClearFiltersButtonForAiArticlesSection = (): boolean => {
	if (isAiArticleSection()) {
		const storeState = store.getState();
		const aiArticlesFilters = (storeState && storeState.aiArticles && storeState.aiArticles.aiArticlesListingFilters) || null;
		if (aiArticlesFilters) {
			const aiArticlesFiltersKeys = Object.keys(aiArticlesFilters);
			return aiArticlesFiltersKeys.length < 3; // includes searchText and articleOrigin by default
		}
	}
	return false;
};

// this is because we are applying by default the article origin filter
export const hideClearFiltersButtonForExternalArticlesSection = (): boolean => {
	if (isExternalArticleSection()) {
		const storeState = store.getState();
		const externalFilters = (storeState && storeState.article && storeState.article.externalArticlesFilters) || null;
		if (externalFilters) {
			const externalFiltersKeys = Object.keys(externalFilters);
			return externalFiltersKeys.length < 3; // includes searchText and articleOrigin by default
		}
	}
	return false;
};

export const getArticleFilters = (state: any) => {
	const articleFiltersMap = {
		ai: state.aiArticles.aiArticlesListingFilters,
		external: state.article.externalArticlesFilters,
		default: state.article.filters,
	};

	const articleType = isAiArticleSection() ? 'ai' : isExternalArticleSection() ? 'external' : 'default';
	return articleFiltersMap[articleType];
};

export const getArticleFiltersUpdate = (dispatch: any) => {
	const articleFiltersUpdateMap = {
		ai: updateAiArticlesAdvancedSearchFilters,
		external: returnObjectForExternalArticlesFiltersUpdate,
		default: returnObjectForArticleFiltersUpdate,
	};

	// Dynamically determine the article type
	const articleType =
		Object.keys(articleFiltersUpdateMap).find((type) => {
			const sectionCheckFunction = {
				ai: isAiArticleSection,
				external: isExternalArticleSection,
			}[type];

			return sectionCheckFunction ? sectionCheckFunction() : false;
		}) || 'default'; // If no match, fallback to 'default'

	return (filters: AdvancedFilterModel) => dispatch(articleFiltersUpdateMap[articleType](filters));
};

export const getExcludedOriginsQueryParam = (excludedOriginsList: string[] = [], requiredFeatures: FeatureTypes[] = []) => {
	const state = store.getState();
	const origins = state.origins.articleOrigins || [];

	const areRequiredFeaturesEnabled = requiredFeatures.some((feature) => featuresService.checkFeatureIsSetAndEnabled(feature));

	if (!areRequiredFeaturesEnabled) {
		return '';
	}

	const excludedOrigins = origins
		.filter((origin: ContentAttributes) => excludedOriginsList.includes(origin.slug) && isOriginAvailable(origin.slug as articleOrigins))
		.map((origin: ContentAttributes) => origin.id)
		.join(',');

	return excludedOrigins ? `&exclude_origin_id=${excludedOrigins}` : '';
};
